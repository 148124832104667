.search_bar_container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    height: 30px;
    background-color: var(--black);
    border-radius: 5px;
    width: 100%;

}

.search_box {
    width: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 4px 0px 0px 4px;
    padding: 5px;
}

.search_box:focus{
    outline: none;
}

.search_btn {
    max-width:40px;
    width: 100%;
    color: var(--lightblue);
    font-size: 20px;
    margin-top: 5px;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
}
.search_bar_container:active{
    background-color: var(--dark);
}