.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.pageContainer{
    display:flex;
    align-items: center;
}

.page{
    margin: 2px;
}

.btn{
    background-color: var(--white);
    border:1px solid var(--dark);
    border-radius:50%;
    
}
.btnfwd{
    transform: rotate(180deg);
}