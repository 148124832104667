
.container{
    width: 100%;
    
}

.container>thead>tr>th {
    border-bottom: 1px solid var(--gray);
    padding: 0.7%;
    font-size: var(--sm-font);
    color:var(--dark)
    
}


.container>tbody>tr>td {
   text-align: center;
   padding: 1%;
font-size: var(--xsm-font);
    color: var(--gray)
}

.container>tbody>tr:nth-child(1){
    background-color: var(--lightblue);
}

.container>tbody>tr:hover{
    background-color: var(--lightgray);
}