.container{
    display: flex;
    flex-direction: column;
}
.accordion {
    /* margin-bottom: 5%; */
    border-bottom: 1px solid #d1d1d1;
    line-height: 1.5em;
    padding-top: 15px;
    padding-bottom: 15px;
}
.suUser{
    margin: 0 0 0 2%;

    border-radius: 5px;
  /* earse when icon ins inserted */
}
.suUser>a{
    text-decoration: none;
    color: var(--dark);
}
.options {
    width: 100%;
    list-style: none;
    padding-left: 10%;
    margin:4% 0;
    display: flex;
   flex-direction: column;
}
.options>li{
    padding: 2% 0;
}
.optionHeader{
    margin: 0 0 0 2%;
    text-decoration: none;
    color:var(--dark)
  
}