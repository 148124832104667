.container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.item_noLink{
    margin: 0 0.9%;
    color:var(--yellow);
    font-weight: 600;
}

.item{
    margin:0 0.9%;
    position: relative;
    text-decoration: none;
    color:var(--dark)  ;
    font-weight: 600;
}
.item:after{
    margin-left:15% ;
    position: absolute;
    content: "";
    height: 90%;
    width: 2px;
    border-radius: 100px;
    transform: skewX(-20deg);
    background-color: var(--dark);
}

@media (max-width:500px) {
    .item {
            margin: 0 2%;
           
        }
    .item:after {
            margin-left: 7%;
            margin-top: 3%;
            height: 80%;
            
        }
}

