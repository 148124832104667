.container{
    display: flex;
    flex-direction: column;
    
    
}

.header{
    display: flex;
    flex-direction: row;
    cursor: pointer;
  


}
.body{
    transition: all ease 0.5s;
    max-height: 0; 
    overflow: hidden;
    background-color: aliceblue;

}

.body_expand{
    transition: all ease 1s;
    max-height: 500px;
    overflow: hidden;
    background-color: aliceblue;
}