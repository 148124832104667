.table_container tr td:last-child,
.table_container tr th:last-child {
  text-align: right;
}

.table_container tr td:first-child,
.table_container tr th:first-child {
  text-align: left;
}

.table_container tr td:nth-child(2)
.table_container tr td:nth-child(3){
  width: 36%;
}