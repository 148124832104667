.container{
    display:flex;
    overflow: hidden;
    border-radius:50%;
    align-items: center;
    justify-content: center;
}

.img{
    height: 100%;
    width: 100%;
}