@import "~react-image-gallery/styles/css/image-gallery.css";
:root{
  --white:#fff;
  --blue:#0F4485;
  --red:#E74A3B;
  --lightblue:#EEF7FC;
  --lightgray:#F4F5F8;
  --gray-600: #3f3f3fb6;
  --gray:#3F3F3F;
  --dark:#323232;
  --borderColor:#D1D3E2;
  --borderRadius:5px;
  --xsm-font:12px;
  --sm-font:14px;
  --md-font:16px;
  --lg-heading:30px;
  --sm-heading: 20px;
  --xsm-heading: 18px;
  --yellow:#cc9900;
  --black:#000000;

}

.icon-primary{
  color: var(--blue);
}

.m-0{
  margin: 0 !important;
}

.mdtitle{
  font-size: var(--md-font);
  font-weight: 800;
  margin: 2px;
}

.smfont{
  font-size: var(--sm-font);
  margin: 2px;
}

.search-container{
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.flex-center{
  align-items: center;
  justify-content: center;
}

.item-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_container {
  background-color: var(--white);
  min-height: 400px;
  position: relative;
  padding: 1em;
  border-radius: 10px;
}
.table_container tr th:first-child{
  text-align: left;
}
.table_container tr td:first-child{
  text-align: left;
}
.pagination {
  position: absolute;
  bottom: 2%;
  right: 2%;

}

.thumbnailContainer {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin:10% auto;
}

.thumbnail {
  height: 100%;

}

.cursor{
  cursor: pointer;
}



.form{
  position: relative;
  padding: 1em;
  background-color: var(--white);  
  border-radius: 0.4em;
  min-width: 600px;
  margin-top: 5%;
  animation: slidein 0.5s ;
  height: 60%;

}

@keyframes slidein {
  from{
    margin-top: -60%;
  }
  to{
    margin-top: 5%;
  }

}
.flex{
  display: flex;
}
.btn {
  padding: 1%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.confirmBtn{
 background-color:#cc9900 ;
 color: var(--white);
 cursor: pointer;
}

.cancleBtn{
  background-color:#000;
  color: var(--white);
  margin-left: 10px;
  cursor: pointer;
}



.form_row{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.form_column{
  flex:0 49%;
  display: flex;
  flex-direction: column;
  margin: 0.5%;
}
.form_column>label{
  color:var(--gray)
}

.form_input{
  border-radius: 5px;
  border: 1px solid rgba(83, 83, 83, 0.295);
  height:30px;
  margin: 2% 0;
  padding: 5px 10px;
}

.form_input:focus{
  outline: none;
}
.submit_btn{
  height:40px !important;
  width:90px !important;
  border-radius: 5px!important;
  background-color: #000000 !important;
  justify-content: center !important;
  align-items: center !important;
}
.residentHeading{
  margin: 20px 0 0 0;
}
.vilaName{
  margin-bottom: 10px;
}

.delete-area{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 18px;
  cursor: pointer;
  position: relative;
  font-weight: lighter;
  line-height: 0;
}
.delete-area:after{
  position: absolute;
  content: "";
  width: 1px;
  height: 20px;
  background: #000;
  left: -10px;
  top: 7px;
}
.delete-area svg{
  width: 25px;
  height: 25px;
}
