@import url('https://fonts.googleapis.com/css?family=Poppins');
body{
  margin: 0;
  padding: 0;
  font-family: Poppins,sans-serif;
}

.text-center{
  text-align: center;
}


/* Inside your CSS file */

.advanced-filter-container {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.filter-row {
  margin-bottom: 10px;
}

.filter-row label {
  display: inline-block;
  width: 100px;
  font-weight: bold;
}

.filter-row select,
.filter-row input[type="text"] {
  width: calc(100% - 110px);
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

.filter-row select {
  cursor: pointer;
}

.filter-row button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-row button:hover {
  background-color: #0056b3;
}
.reqestheading{
   margin: 0 10px 0 0;
   padding: 0;
  
}
.titleheadingcard{ padding: 0; margin: 0 0 20px 0; text-transform: capitalize;}
.resetbtn{ padding: 0.4em 0.9em; font-size: 14px; color: #fff; background-color: #000; margin-right: 10px; border-radius: 15px; border: 0; margin-bottom: 10px; }
.selectbtn{ background-color: #cc9900;}
.mainwrper_filterd{ display: flex; flex-wrap: wrap; gap: 10px;}
.filter-row{ flex: 0 0 49%;}
.mainwrper_filterd .filter-row .react-datepicker-wrapper{width: calc(100% - 110px);}
.mainwrper_filterd .filter-row .react-datepicker-wrapper input[type=text]{ width: 100% !important;}
.btncont_dshoard{ display: flex; justify-content: center; align-items: center; margin: 15px 0 0 0;}

@media (max-width:991px){
  .filter-row{ flex: 0 0 100%;}
}