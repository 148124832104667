.notification{
    display: flex;
    flex-direction: column;
    width: 300px;
}
.header{
    padding: 1% 4%;
    background-color: #4e73df;
    color: var(--white);
    font-size: var(--xsm-font);

}
.footer{
    text-align: center;
    color:var(--borderColor);
    font-size:var(--sm-font);
    font-weight: 100;
    margin: 1%;
    cursor: pointer;
}

.item{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 4% 2%;
    border: 0.2px solid var(--borderColor);
}
.iconContainer{
    
    height: 40px;
    width: 40px;
    border-radius:50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    background-color: #4e73df;
}

.icon{
    height: 100%;
    width: 100%;
}

.message{
    flex: 0 75%;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
}