.btn{
    padding:0.5% 1%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    background-color: black;
}
.bluebtn{
background-color: 'black';
color: var(--white);
}

.darkbtn {
    background-color: black;
    color: var(--white);
}


.bluebtn:hover{
    transition: all ease .2s;
    background-color: var(--dark);
}

.darkbtn:hover{
    transition: all ease .2s;
    background-color: var(--blue);
}