
.overlay{
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}

.form{
    display: flex;
    flex-direction: column;
    max-width:400px;
    width: 100%;
    margin: 5% auto;
    align-items: center;
    padding: 2%;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.568);
}

.input{

    width: 100%;
    margin: 2% 0;
    border :1px solid var(--borderColor) ;
    padding: 2%;
    border-radius: 5px;
    height: 30px;
}

.input:focus{
    outline: none;
}
.loginBtn{
    width: 100%;
    height:50px
}
.bgImage{
    width: 100%;
    position: fixed;
    bottom: 0;
}
