.container{
    display:flex; 
        height: calc(110vh - 125px);
            overflow: hidden;

}

.lgmain{
    transition: all ease 0.5s;
    width: 99%;
    
}

.smmain{
    transition: all ease 0.5s;
    width: 84%;
}




.lgmenu{
    transition: all ease 0.5s;
     width: 19%;
}

.smmenu {
    transition: all ease 0.5s;
    width: 1%;
   
   
}

.main{
    position: relative;
    height: calc(100vh - 155px);
    overflow: auto;
    padding:86px  1.3em ;
    background-color: var(--lightgray);
    
}
.main::-webkit-scrollbar{
    width:10px;
    
}

.menu{
    
    position: relative;
    margin-top: 60px;
    /* background-color: var(--lightblue); */
    box-shadow: 1px 1px 2px var(--lightgray);
}
.menuContainer{
    overflow: hidden;
    width: auto;
    margin:10px;
    padding: 0 2%;
}


.collapseBtn{
    position: absolute;
    background-color: aliceblue;
    box-shadow: 1px 1px 1px var(--gray);
    border-radius: 15px;
    padding: 5px;
    color:var(--dark);
    right:-10px;
    top: 3%;
   
    z-index: 15;
    
   
}

.rotate_180{
    transition: all ease 0.2s;
    transform: rotate(180deg);
  
   
   
}

.rotate_0{
    transition: all ease 0.2s;
    transform: rotate(0deg);
   
}
@media (max-width:500px) {
    .menu {
            position: absolute;
           
            height:100%;
            margin-top: 60px;
            z-index: 1;
          
        }
               
    .lgmenu {
            width: 200px;
          
        }
        
    .smmenu {
            transition: all ease 0.5s;
            width: 0px;
            overflow: hidden;
        
        }
                .lgmain {
                    transition: all ease 0.5s;
                    width: 100%;
                }
        
                .smmain {
                    transition: all ease 0.5s;
                    width: 100%;
                }
                             
                                                              
}


