.container{
    position:fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0,0,0.2);
    z-index: 100;
   
}


.closeButton{
    position: absolute;
    z-index: 1;
    right: -30px;
    top:-20px;
}
.form{
    max-width: 600px;
    position:absolute;
    background-color: var(--white);
    padding: 1em;
    margin:1em;
    left: 0;
    right: 0;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;;
    border-radius: 10px;
    animation: slidein 0.5s;
}

@keyframes slidein {
    from {
        margin-top: -60%;
    }

    to {
        margin-top: 5%;
    }

}