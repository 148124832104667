.container{
    position: relative;
    width: 100%;
}
.dropdown{
    min-width: 200px;
    display: block;
    z-index: 1;
    /* transform: translateX(-45%); */
    position:absolute;
    background-color: var(--white);
    box-shadow: 1px 5px 6px rgba(0,0,0,0.3);
    border-radius: var(--borderRadius);
}

.dropdown_center{
    transform: translateX(-45%);
}

.dropdown_left{
    transform: translateX(-90%);
}

.container:hover .dropdown{
    transition: all ease .2s;
    height:auto;
}


.dropdown_Item{
    padding: 2%;
}
.dropdown_Item:hover{
    transition: all ease 0.2s ;
    background-color: var(--lightblue);
}




