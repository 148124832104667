.container{
    position: fixed;
    background-color: var(--white);
    z-index: 9;
    width: 100%;
    box-shadow:1px 1px 1px var(--lightgray);
    height: 60px;
    /* margin:0 1rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
   
    
}
.navbar_icon{
    height:60px;
    cursor: pointer;
}

.navbar{
    margin: 0 auto;
    width: 97%;
    display: flex;
    align-items: center;
    
}
.admindropdownItem{
    padding-left: 10px;
    text-decoration: none;
    color:var(--dark);
    display: block;
}


.nav{
    
    height: 100%;
    display: flex;
    text-decoration: none;
    list-style: none;
    margin-left: auto;
   
}

.nav_item{
    width:auto;
    display: flex;
    align-items: center;
    justify-content: center;
       
}
.notification{
    position: relative;
    font-size: 30px;
    color: var(--blue);
    padding: 5px;
    
}
.notification_counter{
    position: absolute;
    background-color: var(--red);
    color:var(--lightblue);
    height: 12px;
    width: 15px;
    border-radius: 2px;
    z-index: 1;
    font-size: 8px;
    text-align: center;
    left: 20px;
    top:13px
}


.profileContainer {
    
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: aqua;
    overflow: hidden;
}

.profilepic {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s;
}
.profilepic:hover{
    transform: scale(1.1);
}
.profilepic:active {
    transform: scale(0.95);
}

.user_type {
    margin-right: 1em;
    background-color:#000;
    color: #fff;
    border-radius: 15px;
    font-size: var(--sm-font);
    width: fit-content;
    text-align: center;
    padding : 0.4em 0.5em;
}

.selected_project{
    color: #fff;
    font-size: var(--sm-font);
    border-radius: 15px;
    padding : 0.4em 0.5em;
    margin-right: 1em;
    background-color: #cc9900;
    text-transform: uppercase;
}

